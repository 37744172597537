<template>
  <!-- iframeはシングルカラム -->
  <div class="pb-16">
    <v-card>
      <!-- イベントサムネイル -->
      <event-thumbnail :eventId="$route.params.id" />
      <!-- イベント詳細 -->
      <event-detail :event="event" />
      <v-divider />
      <!-- 配信コンテンツ -->
      <event-content-tab :eventId="$route.params.id" />
    </v-card>
    <!-- チケット -->
    <event-item
      v-if="!getBuy"
      @checkDuplicate="$emit(checkDuplicate)"
      :event="event"
      :isMobile="isMobile"
    />
    <!-- お問合せ -->
    <event-contact
      v-if="!event.is_ended"
      :event="event"
    />
  </div>
</template>
<script>
import EventThumbnail from '@/components/organisms/EventThumbnail'
import EventDetail    from '@/components/organisms/EventDetail'
import EventContentTab  from '@/components/organisms/EventContentTab'
import EventItem      from '@/components/organisms/EventItem'
import EventContact  from '@/components/organisms/EventContact'

export default{
  name: 'EventIFrame',
  components: {
    EventThumbnail,
    EventDetail,
    EventContentTab,
    EventItem,
    EventContact
  },
  provide:{
    isEmbedded: true // ModalItemBuyで使用
  },

  props:{
    event: Object,
    eventId: String,
    getBuy: Boolean
  },
  data(){
    return{
      isMobile: true
    }
  }
}
</script>
