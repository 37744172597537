<template>
  <v-card flat>
    <v-card-title class="justify-center">
      配信コンテンツ
    </v-card-title>
    <v-card-text>
      <!-- コンテンツサムネイル一覧 -->
      <v-sheet
        class="mx-auto"
        max-width="800"
      >
        <v-slide-group
          v-model="selectedContent"
          class="pa-4"
          mandatory center-active show-arrows
        >
          <v-slide-item
            v-for="content in eventContents"
            :key="content.content_id"
            v-slot="{ active, toggle }"
          >
            <v-sheet
              @click="toggle"
              class="ma-4" 
              :class="{activeTile: active, nonActiveTile: !active}"
              elevation="4"
            >
              <v-row
                class="fill-height ma-0"
                align="center" justify="center"
              >
                <template v-if="content.thumbnails[0]">
                  <v-img
                    :src="content.thumbnails[0].url"
                    aspect-ratio=1
                    class="ma-0"
                  />
                </template>
              </v-row>
            </v-sheet>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <v-divider />
      <!-- コンテンツ内容 -->
      <v-tabs-items v-model="selectedContent">
        <v-tab-item
          v-for="eventContent in eventContents" :key="eventContent.id"
        >
          <Content
            :contentId = "eventContent.content_id"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
import Content from '@/components/organisms/Content'

export default{
  name: 'EventContents',
  components:{
    Content
  },
  props:{
    eventId: String
  },
  data(){
    return{
      eventContents: [],
      selectedContent: 0
    }
  },
  async mounted(){
    const eventContents = await this.wc.get('event_content', `w/event_id,${this.eventId}`).catch((err) => {
      console.log(err)
    })
    if(!eventContents) return

    for(const content of eventContents){
      const thumbnails = await this.wc.get('content_thumbnail', `w/content_id,${content.content_id}`).catch((err) => {
        console.log(err)
      })
      content.thumbnails = thumbnails || [{url: ''}]
    }
    this.eventContents = eventContents
  },
}
</script>
<style scoped>
>>>.v-slide-group__content{
  justify-content: center;
  align-items: center;
}
.activeTile{
  height: 100px;
  width: 100px;
}
.nonActiveTile{
  height : 60px;
  width : 60px;
}

</style>
